import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { FootnotesFeature } from '@hyundai/ng-common-lib';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-footnotes',
    templateUrl: './footnotes.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class FootnotesComponent {
    store = inject(Store);
    footnotes = this.store.selectSignal(FootnotesFeature.state.selectNumberedFootnotes);
}
