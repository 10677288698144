@if (footnotes().length > 0) {
    <div class="inner-page-wrapper flex flex-col gap-16 bg-white py-24 text-14 leading-20 text-grey-400 empty:mt-0">
        @for (footnote of footnotes(); track footnote.id) {
            <div class="flex flex-row" [id]="footnote.id">
                <span class="mr-8 font-medium">{{ footnote.sign }}</span>
                <div [innerHTML]="footnote.content"></div>
            </div>
        }
    </div>
}
